import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { AbstractBaseComponent } from './abstract-base/abstract-base.component';
import { AppStateService } from '@services/app-state.service';
import { BaseModalComponent } from './base-modal/base-modal.component';
import { BrSdkModule } from '@bloomreach/ng-sdk';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { DebugComponent } from './debug/debug.component';
import { DebugService } from '@services/debug.service';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpLink } from 'apollo-angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from './translate/translate.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { DebugServiceToken } from 'src/app/types/debug-service.interface';
import { CommonComponent } from '@components/marketing/common/common.component';
import { DisplayContentComponent } from '@components/marketing/display-content/display-content.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CallBackFormComponent } from './call-back-form/call-back-form.component';
import { CalculatorFormComponent } from './calculator-form/calculator-form.component';
import { AccordionComponent } from '@marketing/accordion/accordion.component';
import { DocumentPanelComponent } from './document-panel/document-panel.component';
import { FundCaveatComponent } from '@components/marketing/fund-caveat/fund-caveat.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DatePickerModule } from '@shared/date-picker/date-picker.module';
import { PiChartComponent } from './pi-chart/pi-chart.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { FocusFundCardComponent } from './focus-fund-card/focus-fund-card.component';
import { MediaArticlesComponent } from "@marketing/media-articles/media-articles.component";
import { SectionTitleComponent } from '@marketing/section-title/section-title.component';
import { ArticlesComponent } from '@marketing/media-articles/articles/articles.component';
import { MediaReleasesComponent } from '@marketing/media-articles/media-releases/media-releases.component';
import { IndCalculatorsComponent } from '@marketing/ind-calculators/ind-calculators.component';
import { ArticleDetailComponent } from '@components/marketing/article-detail/article-detail.component';
import { VideoArticlesComponent } from '@components/marketing/video-articles/video-articles.component';
import { ArticleListComponent } from '@components/marketing/article-list/article-list.component';
import { MarketInsightsComponent } from "@marketing/market-insights/market-insights.component";

//search related
import { AutoSuggestionsListComponent } from './header/auto-suggestions-list/auto-suggestions-list.component';
import { SearchComponent } from '@shared/search/search.component';
import { SearchResultsGeneralComponent } from "@shared/search/search-results-general/search-results-general.component";
import { SearchResultsFundsComponent } from "@shared/search/search-results-funds/search-results-funds.component";
import {
  SearchResultsInvestorComponent
} from "@shared/search/search-results-investor/search-results-investor.component";
import { SearchResultsAdvisorComponent } from "@shared/search/search-results-advisor/search-results-advisor.component";
import {
  SearchResultsLiteratureComponent
} from "@shared/search/search-results-literature/search-results-literature.component";
import { IndNgxSlidesComponent } from "@marketing/ind-ngx-slides/ind-ngx-slides.component";
import { IndGlosarryComponent } from '@marketing/ind-glosarry/ind-glosarry.component';

import {
  IDCWHistoryTableComponent
} from '@components/products/other-details/idcw-history/idcw-history-table/idcw-history-table.component';
import { NgxPrintModule } from 'ngx-print';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FundNavModalComponent } from './models/fund-nav-modal/fund-nav-modal.component';
import { IDCWHistoryModalComponent } from './models/idcw-history-modal/idcw-history-modal.component';
import { ImintrestedformComponent } from './imintrestedform/imintrestedform.component';
import { CodeComponent } from "@components/code/code.component";
import {
  ReachforbetterarticlePageComponent
} from "@marketing/reachforbetterarticle-page/reachforbetterarticle-page.component";
import { DisplayListComponent } from "@marketing/display-list/display-list.component";
import { SearchDataComponent } from './header/search-data/search-data.component';
import {
  HomeFundCardComponent
} from '@components/products/fund-listing/home-fund-listing/home-fund-card/home-fund-card.component';
import { CartComponent } from '@components/products/fund-listing/cart/cart.component';
import { FundAgePipe } from '@components/products/fund-listing/fund-age.pipe';
import { SpeakToUsDataComponent } from './header/speak-to-us-data/speak-to-us-data.component';
import { SearchFundCardComponent } from './search/search-results-funds/search-fund-card/searchfund-card.component';
import { EmailUsFormComponent } from './email-us-form/email-us-form.component';
import { KcLandingComponent } from '@marketing/kc-landing/kc-landing.component';
import { SearchKnowledgeCentreComponent } from './search-knowledge-centre/search-knowledge-centre.component';
import { LazyImgDirective } from "@shared/directives/button/lazyimg.directive";

// recaptcha
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';


export function createApolloOptions(
  httpLink: HttpLink,
  appStateService: AppStateService,
  debugService: DebugService
): ApolloClientOptions<any> {
  let defaultEnv = debugService.getPdsEnv();
  if (defaultEnv === '') {
    defaultEnv = appStateService.getPdsDefaultEnv();
    debugService.setPdsEnv(defaultEnv);
  }

  return {
    link: httpLink.create({
      uri: (operation) =>
        `${appStateService.getPdsApiUrl(defaultEnv)}&op=${
          operation.operationName
        }`,
    }),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  };
}

// Components
@NgModule({
  imports: [
    BrSdkModule,
    CommonModule,
    DatePickerModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    SlickCarouselModule,
    NgOptimizedImage,
    HighchartsChartModule,
    NgbTooltip,
    NgxSliderModule,
    NgxPrintModule,
    NgbModule, NgbPaginationModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  declarations: [
    AbstractBaseComponent,
    BarChartComponent,
    BaseModalComponent,
    LazyImgDirective,
    DebugComponent,
    HeaderComponent,
    FooterComponent,
    CallBackFormComponent,
    CalculatorFormComponent,

    AutoSuggestionsListComponent,
    SearchComponent,
    SearchResultsGeneralComponent,
    SearchResultsFundsComponent,
    SearchResultsInvestorComponent,
    SearchResultsAdvisorComponent,
    SearchResultsLiteratureComponent,
    SearchFundCardComponent,
    SearchKnowledgeCentreComponent,
    DocumentPanelComponent,
    PiChartComponent,
    PageNotFoundComponent,
    FocusFundCardComponent,
    MediaArticlesComponent,
    ArticlesComponent,
    MediaReleasesComponent,
    IDCWHistoryTableComponent,
    FundNavModalComponent,
    IDCWHistoryModalComponent,
    ImintrestedformComponent,
    CodeComponent,
    SearchDataComponent,
    HomeFundCardComponent,
    CartComponent,
    FundAgePipe, SpeakToUsDataComponent, EmailUsFormComponent
  ],
  exports: [
    ApolloModule,
    AbstractBaseComponent,
    BarChartComponent,
    BaseModalComponent,
    LazyImgDirective,
    DebugComponent,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    FormsModule,
    CallBackFormComponent,
    CalculatorFormComponent,
    DocumentPanelComponent,
    PiChartComponent,
    HighchartsChartModule,
    DatePickerComponent,
    PageNotFoundComponent,
    SlickCarouselModule,
    NgxSliderModule,
    FocusFundCardComponent,
    IDCWHistoryTableComponent,
    NgxPrintModule,
    NgbModule, NgbPaginationModule,
    FundNavModalComponent,
    IDCWHistoryModalComponent,
    ImintrestedformComponent,
    HomeFundCardComponent,
    CartComponent,
    FundAgePipe,
    SearchKnowledgeCentreComponent
  ],
  providers: [
    // order matters below
    {provide: DebugService, useClass: DebugService},
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApolloOptions,
      deps: [HttpLink, AppStateService, DebugService],
    },
    {provide: DebugServiceToken, useExisting: DebugService}, // for EDS
    DatePipe, // for literatture-ag-grid-helper service
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ],
})
export class SharedModule {
}

export const SharedComponentMapping = {
  Header: HeaderComponent,
  Footer: FooterComponent,
  'ind-code': CodeComponent,
  'IND Search Component': SearchComponent,
  'IND Page Not Found': PageNotFoundComponent,
  'ind-common': CommonComponent,
  'ind-fund-caveat': FundCaveatComponent,
  'IND Glossary Component': IndGlosarryComponent,
  'ind-reach-for-better-work': ReachforbetterarticlePageComponent,
  'ind-display-list': DisplayListComponent,
  //new components getting developed goes below
  'ind-redesign-main': DisplayContentComponent,
  'ind-redesign-accordion': AccordionComponent,
  'ind-redesign-calculators': IndCalculatorsComponent,
  'ind-media-articles': MediaArticlesComponent,
  'IND Section Title': SectionTitleComponent,
  'ind-article-details': ArticleDetailComponent,
  'ind-video-articles': VideoArticlesComponent,
  'ind-article-list': ArticleListComponent,
  'ind-market-insights-blogs': MarketInsightsComponent,
  'ind-redesign-slides': IndNgxSlidesComponent,
  'ind-kc-landing': KcLandingComponent,
};
