<!-- start page breadcrumb block -->
<div class="FT-breadcrumb d-none d-sm-block" *ngIf="isBreadcrumbShown">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="[segmentSelected ==='Distributor'?'/distributor':'/']" aria-label="Home">Home</a>
      </li>
      <ng-container *ngIf="breadcrumbs?.length > 0">
        <ng-container *ngFor="let item of breadcrumbs; let last = last">
          <li class="breadcrumb-item" *ngIf="item?.link && !last">
            <a [routerLink]="item.link">
              {{ item?.name }}
            </a>
            <!--<a *ngIf="!item?.link && !last" style="display:none;">
              {{ item?.name }}
            </a>
            <ng-container *ngIf="last">{{ item?.name }}</ng-container>-->
          </li>
          <li class="breadcrumb-item" [class]="last?'active' : ''" *ngIf="last">
            {{ item?.name }}
          </li>
        </ng-container>
      </ng-container>
    </ol>
  </div>
</div>
<div class="main-content d-md-none d-lg-none" *ngIf="!isToolsPage">
  <div class="container">
    <div class="back-btn custom-back-btn">
      <a style="cursor: pointer;" (click)="backClicked()">
        <img alt="image" src="assets/images/general/arrowleft.webp" class="d-inline-block mobile-icons light-mode-icon">
        <img alt="image" src="assets/images/fund/Vec.svg" class="mobile-icons dark-mode-icon" style="width: 20px; margin-top: 8px;">
      </a>
    </div>
  </div>
</div>
