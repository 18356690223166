import { Location } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FundHeaderService } from '@components/products/overview/services/fund-header.service';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { TitleService } from '@services/title.service';
import { Subscription } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { EnvConfigService } from "@services/env-config.service";

@Component({
  selector: 'ft-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  @Input() page;
  breadcrumbs: any = [];
  isBreadcrumbShown = true;
  dataSubscription: Subscription;
  isToolsPage: boolean = false;
  segmentSelected: string;

  constructor(
    private titleService: TitleService,
    private envConfig: EnvConfigService,
    private location: Location,
    private breadcrumbService: BreadcrumbService,
    private fundHeaderService: FundHeaderService,
    private elementRef: ElementRef,
    private metaService: Meta
  ) {
  }

  ngOnInit(): void {
    const home = 'Home';
    if (this.location.path().indexOf('funds-and-solutions/tools') > 0) {
      this.isToolsPage = true;
    }
    if (this.isFundPage()) {
      // console.log("BC: IF");
      this.dataSubscription = this.fundHeaderService
        .getFundHeaderState$()
        .subscribe((state) => {
          this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(
            this.page,
            state.fundName
          );
          this.addSchema();
        });
    } else {
      //console.log("BC: else")
      this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.page);
      this.addSchema();
    }

    this.isBreadcrumbShown = !this.page?.getComponent().getModels()?.pageData
      ?.hideBreadcrumbs;

    // Meta title
    const pageData = this.page?.getComponent().getModels()?.pageData;
    if (!pageData?.metaTitle) {
      if (this.location.path().indexOf('about-us') > 0) {
        this.titleService.setTitle('About Us');
        this.metaService.updateTag(
          {
            property: 'og:title',
            content: 'About Us',
          },
          "property='og:title'"
        );
      } else {
        const lastElement = this.breadcrumbs?.length
          ? this.breadcrumbs[this.breadcrumbs?.length - 1]
          : home;
        const metaTitle = lastElement.name;
        if (metaTitle && !this.isFundPage()) {
          this.titleService.setTitle(metaTitle);
        }
      }
    }
    const isDistributor = this.location.path().includes('/distributor');
    this.segmentSelected = localStorage.getItem("current-segment");
    if (this.segmentSelected && isDistributor) {
      this.segmentSelected = "";
    }
  }

  isFundPage(): boolean {
    return this.page?.getUrl()?.startsWith('/fund-details');
  }

  private addSchema() {
    const urlRoot = this.envConfig.getEnvConfig()?.baseUrl.replace(/\/$/, "");
    const breadcrumbs = this.breadcrumbs?.map((breadcrumb, i) => ({
      '@type': 'ListItem',
      position: i + 2,
      item: {
        '@type': 'WebPage',
        '@id': breadcrumb.link ? urlRoot + breadcrumb.link : '',
        name: breadcrumb.name,
      },
    }));
    const schema = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            '@type': 'WebPage',
            '@id': urlRoot,
            name: 'Home',
          },
        },
        ...(breadcrumbs ? breadcrumbs : []),
      ],
    };

    // set url of leaf
    schema.itemListElement[schema.itemListElement.length - 1].item['@id'] =
      urlRoot + this.page?.getUrl().replace(/\/_index_$/, '');

    // add to page
    const s = document?.createElement('script');
    s.type = 'application/ld+json';
    s.innerText = JSON.stringify(schema);
    this.elementRef.nativeElement.appendChild(s);
  }

  backClicked() {
    this.location.back();
  }

  ngOnDestroy(): void {
    // Unsubscribe fundHeaderService subscriber.
    this.dataSubscription?.unsubscribe();
  }
}
