<div class="regBanner" *ngIf="contentType === 'Notification-Banner'">
  <ft-regulatory-updates [component]="component" [page]="page"></ft-regulatory-updates>
</div>

<div class="mainBanner" *ngIf="contentType === 'Main-Banner'">
  <ft-ind-carousel [component]="component" [page]="page"></ft-ind-carousel>
</div>

<div class="locationsMap" *ngIf="contentType === 'Locator-Map'">
  <ft-locations [component]="component" [page]="page"></ft-locations>
</div>

<div class="solutionsCard" *ngIf="contentType === 'Solutions-Card'">
  <ft-investment-services [component]="component" [page]="page"></ft-investment-services>
</div>

<div class="homePageBlogs" *ngIf="contentType === 'Home-Page-Blogs'">
  <ft-home-page-blogs [component]="component" [page]="page"></ft-home-page-blogs>
</div>

<div class="CheckProgress" *ngIf="contentType === 'Where-Do-You-Stand'">
  <ft-check-progress [component]="component" [page]="page"></ft-check-progress>
</div>

<div class="goalCalc" *ngIf="contentType === 'Goals-Calculator'">
  <ft-goals-calculator [component]="component" [page]="page"></ft-goals-calculator>
</div>

<div class="digitizeWealth" *ngIf="contentType === 'Quick-Links'">
  <ft-digitize-wealth [component]="component" [page]="page"></ft-digitize-wealth>
</div>

<div class="leadForm" *ngIf="contentType === 'Lead-Form'">
  <ft-invest-now-form [component]="component" [page]="page"></ft-invest-now-form>
</div>

<div class="downloadMobileApps" *ngIf="contentType === 'Download-App'">
  <ft-download-app [component]="component" [page]="page"></ft-download-app>
</div>

<div class="richText" *ngIf="contentType === 'Rich-Text'">
  <ft-rich-text [component]="component" [page]="page"></ft-rich-text>
</div>

<div class="accordionTab" *ngIf="contentType === 'Accordion-Tab'">
  <ft-accordion-tab [component]="component" [page]="page"></ft-accordion-tab>
</div>

<div class="calculatorSolutions" *ngIf="contentType === 'Calculator-Solutions'">
  <ft-solutions-carousel [component]="component" [page]="page"></ft-solutions-carousel>
</div>

<div class="otherCalculator" *ngIf="contentType === 'Other-Calculator'">
  <ft-more-calculators [component]="component" [page]="page"></ft-more-calculators>
</div>

<div class="addressBook" *ngIf="contentType === 'Address-Book'">
  <ft-address-book [component]="component" [page]="page"></ft-address-book>
</div>

<div class="linkList" *ngIf="contentType === 'Link-List'">
  <ft-link-list [component]="component" [page]="page"></ft-link-list>
</div>
<div class="stickyTabs" *ngIf="contentType === 'Horizontal-Tabs'">
  <ft-sticky-tabs [component]="component" [page]="page"></ft-sticky-tabs>
</div>
<div class="feedbackForm" *ngIf="contentType === 'Feedback-Form'">
  <ft-feedback [component]="component" [page]="page"></ft-feedback>
</div>
<div class="distributorOfferings" *ngIf="contentType === 'Distributor-Offerings'">
  <ft-distributor-offerings [component]="component" [page]="page"></ft-distributor-offerings>
</div>