<ng-template [ngIf]="mapping && page && configuration">
  <div id="loader" class="ftic-ajax-loader text-center" style="display: none">
    <div class="ftic-ajax-loader-content text-center ftic-overlay">
      <img src="https://franklintempletonprod.widen.net/content/kelqgnkl5z/web/loader.gif" alt="Please wait"/>
      <div>Please wait...</div>
    </div>
  </div>
  <br-page [configuration]="configuration" [mapping]="mapping" [page]="page">
    <ng-template let-page="page">
      <header *ngIf="!isBetterLifeVisible && !emi && !isMarathonPage">
        <ng-container brComponent="header-content"></ng-container>
      </header>
      <section class="main-section"
               *ngIf="layout !== 'home-page' && layout !=='profile-and-marketo-form' && layout !=='large-tabs' && layout !=='small-tabs' && layout !=='pagenotfound'&& layout !=='smallTabsLayout' && !isFundPages && !isBetterLifeVisible && !isMarathonPage">
        <div id="page-content"
             [ngClass]="calculatorPage?'custBg':'' || bgImage?'bg-img':''">
          <ft-breadcrumb [page]="page"></ft-breadcrumb>
          <section class="FT-footer-pages" [ngClass]="isMobileView? 'pt-3':'pt-5'"
                   *ngIf="layout =='full-width' else elseblock">
            <div class="container">
              <ng-container brComponent="main-content"></ng-container>
            </div>
          </section>
          <section *ngIf="layout =='full-width'">
            <ng-container brComponent="lower-content"></ng-container>
          </section>
          <ng-template #elseblock>
            <ng-container brComponent="main-content"></ng-container>
          </ng-template>
        </div>
      </section>
      <section class="main-section" *ngIf="isBetterLifeVisible">
        <ng-container brComponent="main-content"></ng-container>
      </section>
      <section class="main-section" *ngIf="layout =='full-width' && isMarathonPage">
        <ng-container brComponent="main-content"></ng-container>
      </section>
      <ng-container *ngIf="!isBetterLifeVisible && !emi && !isMarathonPage">
        <section [ngSwitch]="layout">
          <section *ngSwitchCase="'home-page'">
            <section>
              <ng-container brComponent="carousel-content"></ng-container>
            </section>
            <section>
              <ng-container brComponent="quick-links"></ng-container>
            </section>
            <section>
              <ng-container brComponent="updates-blogs">
                <ft-latest-updates [limit]="4"></ft-latest-updates>
              </ng-container>
            </section>
          </section>
          <ng-container *ngSwitchCase="'twocol-leftwide'">
            <div id="page-content">
              <ft-breadcrumb
                  [page]="page"
              ></ft-breadcrumb>
              <ft-two-col-left-wide></ft-two-col-left-wide>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'large-tabs'">
            <div id="page-content">
              <ft-breadcrumb [page]="page"></ft-breadcrumb>
              <ft-onecol></ft-onecol>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'video-inner'">
            <ng-container brComponent="video-content"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'pagenotfound'">
            <ng-container brComponent="main-content"></ng-container>
          </ng-container>
        </section>
        <ng-container
            *ngIf="layout ==='profile-and-marketo-form' || layout==='small-tabs' || layout==='smallTabsLayout'">
          <div id="page-content" class="bg-img">
            <ft-breadcrumb [page]="page"></ft-breadcrumb>
            <div class="small-tabs-lead-content">
              <ng-container brComponent="lead-content"></ng-container>
            </div>
            <div
                [ngClass]="{ 'FT-location pt-5': layout ==='profile-and-marketo-form' , 'FT-Franklin-info': layout ==='small-tabs','FT-touchPoints': layout ==='smallTabsLayout'}">
              <div class="container">
                <ng-container brComponent="main-content"></ng-container>
              </div>
            </div>
            <div class="small-tabs-lower-content">
              <ng-container brComponent="lower-content"></ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <footer *ngIf="!isBetterLifeVisible && !emi && !isMarathonPage" class="footer-content">
        <ng-container brComponent="footer-content"></ng-container>
      </footer>
    </ng-template>
  </br-page>
</ng-template>
