import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Component as BrComponent, Page } from "@bloomreach/spa-sdk";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { WINDOW } from "@ng-web-apis/common";
import { Observable, Subject } from "rxjs";
import { EnvConfigService } from "@services/env-config.service";
import { TranslateService } from "@shared/translate/translate.service";
import { AppStateService } from "@services/app-state.service";
import { Logger } from "@utils/logger";
import { replaceTokens } from "@utils/text/string-utils";
import { DOCUMENT, Location } from "@angular/common";
import { getMenus, getModelFromRef } from "@utils/pagemodel-utils";
import { CommonService } from "@services/common.service";
import { AnalyticsService } from "@services/analytics.service";
import { StorageService } from "@services/storage.service";
import { LoginService } from "@services/login.service";
import { takeUntil } from "rxjs/operators";
import { InUserType } from "@types";
import { SafeHtml } from "@angular/platform-browser";

const logger = Logger.getLogger("HeaderComponent");

export interface DataWindow extends Window {
  // TODO: define type for dataLayer
  dataLayer: any[];
}

@Component({
  selector: "ft-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  activeMobileHeaderId: any = '';
  @Input() component!: BrComponent;
  @Input() page: Page;
  public menuItems;
  public menu;
  public searchText = "";
  public baseUrl = "";
  public isLoggedIn: boolean;
  public userType: string;
  public getFtCountryUrl: any;
  public getFtcountryMenuLabel: any;
  public subChildMenuList: any;
  public checkboxActive: boolean = false;
  noticeBannerIsOpened: boolean = false;
  ftiOutageMessage: any;
  ftiOutageMessageCloseValue: any;
  optionalsBannerClose: boolean;
  ftiOutageMessageCloseOption: any;
  ftiOutageMessageValue: any;
  ftiOutageMessageTitle: any;
  public menuList;
  public mobileDDmenu;
  public topMenuList;
  private mainMenuList = [];
  private accountsBaseUrl = "";
  private queryParams: Params;
  private unsubscribe$: Subject<void> = new Subject<void>();
  lastKnownScrollPosition!: number;
  ShowSideMenu = false;
  ShowSearch = false;
  ShowSuggest = false;
  ShowRecent = true;
  isMobileView: boolean = false;
  segmentSelected: string;
  btnLoginText: string;
  getAlert: boolean = false;

  @Input() isSmall = false;
  @Input() isCallback = false;
  public showAutoSuggestions = false;
  public triggerChange = false;
  loginURL: string;
  siteToggle: boolean = false;
  isMidScreen: boolean = false;
  midLaptop: boolean = false;
  siteToggleVal: string;
  siteToggleStatus: string;


  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    let topSpacElement: any = document.getElementById('handle-top-spacing');
    if (!this.noticeBannerIsOpened) {
      if (window.innerWidth < 768) {
        topSpacElement?.classList.add('FT-default-top-mob');
        topSpacElement?.classList.remove('FT-default-top-web');
      } else {
        topSpacElement?.classList.remove('FT-default-top-mob');
        topSpacElement?.classList.add('FT-default-top-web');
      }
    }
    this.isMidScreen = window.innerWidth < 991 && window.innerWidth > 768;
    this.midLaptop = window.innerWidth > 990 && window.innerWidth < 1510;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any) {
    const deltaY = window.scrollY - this.lastKnownScrollPosition;
    this.lastKnownScrollPosition = window.scrollY;
    let menu: any = document.querySelector(".header");
    let topSpacElement: any = document.getElementById('handle-top-spacing');
    let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    let header: any = document.getElementById('mainHeader');
    let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    let scrolled = (winScroll / height) * 100;

    let headerHeight = (header.offsetHeight - 20);

    let progressBar: any = document.getElementById("myBar")
    progressBar.style.width = scrolled + "%";
    const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    if (deltaY > 0 && winScroll > headerHeight) {
      menu?.classList.add("scroll-down");
      if (window.innerWidth < 767) {
        if (this.noticeBannerIsOpened) {
          document.documentElement.style.setProperty('--mainContentMobileMargin', '300px');
          menu?.classList.add("mobile-fix-top");
          topSpacElement?.classList.add('fix-mobile-margin');
        }
        // this.noticeBan ? : '';
      } else if (window.innerWidth >= 768 && window.innerWidth < 830) {
        // menu?.classList.add('fix-tablet-top-margin');\
        if (this.noticeBannerIsOpened) {
          document.documentElement.style.setProperty('--mainContentMargin', '287px');
          menu?.classList.add('fix-tablet-top-margin')
        } else {
          menu?.classList.add('fix-tablet-top-margin2');
        }
      } else {
        browserZoomLevel > 100 ? header?.classList.add('fix-zoom-header-styling') : header?.classList.remove('fix-zoom-header-styling');
        if (this.noticeBannerIsOpened) {
          document.documentElement.style.setProperty('--mainContentMargin', '287px');
          // topSpacElement?.classList.add('fix-margin');
          menu?.classList.add("web-fix-top");
        }
      }
    } else {
      menu.classList.remove("scroll-down");
      if (window.innerWidth < 767) {
        if (this.noticeBannerIsOpened) {
          document.documentElement.style.setProperty('--mainContentMobileMargin', '97px;');
          menu?.classList.remove("mobile-fix-top");
          topSpacElement?.classList.remove('fix-mobile-margin');
        }
      } else if (window.innerWidth >= 768 && window.innerWidth < 830) {
        this.noticeBannerIsOpened ? menu?.classList.remove('fix-tablet-top-margin')
          : menu?.classList.remove('fix-tablet-top-margin2');
      } else {
        browserZoomLevel > 100 ? header?.classList.remove('fix-zoom-header-styling') : '';
        if (this.noticeBannerIsOpened) {
          document.documentElement.style.setProperty('--mainContentMargin', '126px;');
          // topSpacElement?.classList.remove('fix-margin');
          menu?.classList.remove("web-fix-top");
        }
      }

    }
    const scrollTopBtn = this.document.getElementById('scrollTopBtn')
    if (winScroll > 550) {
      scrollTopBtn?.classList.remove('d-none')
    } else {
      scrollTopBtn?.classList.add('d-none')
    }
  }

  @HostListener('window:click', ['$event'])
  onWindowClick(event: Event) {
    const className = (event.target as Element).className;
    if (className.includes('accordion-button') || className.includes('dropdown-toggle') || className.includes('block-head') || className.includes('bi-chevron-right')) {
      // console.log('Hello if');
    } else {
      // console.log('hello else');
      this.activeMobileHeaderId = '';
    }
  }

  @HostListener('click', ['$event'])
  onClickDateRangePicker(event: Event) {
    if (this.ShowSideMenu) {
      if ((event.target as Element).className == 'page-overlay') {
        this.ShowSideMenu = false;
        this.activeMobileHeaderId = '';
      }
    }
  }

  showModal: boolean = true;
  recentSearches: any;
  suggestions: any = [];
  public maintenance$: Observable<SafeHtml>;
  public validated: boolean;

  // prettier-ignore
  constructor( // NOSONAR - typescript:S107 needs mor than 7 parameters in constructor
    private activatedRoute: ActivatedRoute,
    private envConfig: EnvConfigService,
    private translateService: TranslateService,
    private appStateService: AppStateService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private location: Location,
    private renderer: Renderer2,
    private analyticsService: AnalyticsService,
    private commonService: CommonService,
    private storageService: StorageService,
    private loginService: LoginService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private documentRef: Document,
    @Inject(WINDOW) private windowRef: DataWindow,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.siteToggleStatus = this.translateService.instant(
      'ftiGeneric.site-toggle'
    );
    if (this.siteToggleStatus === 'true') {
      this.siteToggle = true;
    }
    this.siteToggleVal = this.translateService.instant(
      'ftiGeneric.site-toggle-value'
    );
    this.ftiOutageMessage = this.translateService.instant(
      'ftiGeneric.outage-msg-status'
    );
    this.ftiOutageMessageCloseValue = this.translateService.instant(
      'ftiGeneric.outage-msg-close-btn-label'
    );
    this.ftiOutageMessageCloseOption = this.translateService.instant(
      'ftiGeneric.outage-msg-close-option'
    );

    this.optionalsBannerClose = this.ftiOutageMessageCloseOption?.toLowerCase() === 'false';

    this.ftiOutageMessageValue = this.translateService.instant(
      'ftiGeneric.outage-msg-value'
    );
    this.ftiOutageMessageTitle = this.translateService.instant(
      'ftiGeneric.outage-msg-title'
    );
    const cookkievalues = document.cookie.indexOf('outageBanner=');
    this.noticeBannerIsOpened = cookkievalues === -1 && this.ftiOutageMessage === 'true';

    this.loginService.getValidated$()?.pipe(takeUntil(this.unsubscribe$)).subscribe(validated => {
      this.validated = validated;
      this.changeDetector.detectChanges();
    });
    this.maintenance$ = this.loginService.checkMaintenance();
  }

  ngOnInit(): void {
    this.isMidScreen = window.innerWidth < 991 && window.innerWidth > 768;
    this.midLaptop = window.innerWidth > 990 && window.innerWidth < 1510;
    if (window.innerWidth < 700) {
      this.isMobileView = true;
    }
    //Apply if any font-size preference
    this.commonService.setFontResize();
    this.initMenu();

    this.baseUrl = this.envConfig.getEnvConfig()?.baseUrl;
    this.accountsBaseUrl = this.envConfig.getEnvConfig()?.ftiAccountsUrl;
    this.queryParams = this.activatedRoute.snapshot.queryParams;
    this.searchText = this.queryParams?.query;

    /**
     * Set the segment based on the local storage/URL value.
     */
    const isDistributor = this.location.path().includes('/distributor');
    const segmentSelected = localStorage.getItem("current-segment");
    let isInvHome = this.commonService.isHome(this.page?.getUrl());
    if (!isInvHome && (isDistributor || segmentSelected)) {
      this.segmentSelected = 'Distributor';
    } else {
      this.segmentSelected = '';
    }

    this.handleSegmentChange(this.segmentSelected);

    const invLoginBtnLabel = this.translateService.instant(
      'ftiGeneric.investor-login-label'
    );
    const invLoginURL = this.translateService.instant(
      'ftiGeneric.investor-login-url'
    );
    const distLoginBtnLabel = this.translateService.instant(
      'ftiGeneric.distributor-login-label'
    );
    const distLoginURL = this.translateService.instant(
      'ftiGeneric.distributor-login-url'
    );

    if (this.segmentSelected === 'Distributor') {
      this.btnLoginText = distLoginBtnLabel;
      this.loginURL = this.appStateService.getftiAccountsUrl() + distLoginURL;
    } else {
      this.btnLoginText = invLoginBtnLabel;
      this.loginURL = this.appStateService.getftiAccountsUrl() + invLoginURL;
    }
    this.changeDetector.detectChanges();
    let getThemeMode = localStorage.getItem("en-in-theme-mode");
    if (getThemeMode === 'dark') {
      this.document.body.classList.add("page-dark-mode");
      this.checkboxActive = true;
    } else {
      this.document.body.classList.remove("page-dark-mode");
      this.checkboxActive = false;
    }
    this.fixNotificationHeader();
    this.loadRecentSearches();
    this.suggestions = JSON.parse(this.translateService.instant("ftiGeneric.search-suggestions"));

    // Set alert modal for logout button
    this.loginService
      .getUserFeedbackDisplay$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((feedback) => {
      });

    // Set alert modal for session end
    this.loginService
      .getLoginSession$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((sessionLogin) => {
        if (!sessionLogin) {
        }
      });

    this.storageService
      .retrieve('feedbackSubmitted', true)
      .then((isFeedbackSubmitted) => {
        if (isFeedbackSubmitted) {
          /*this.feedbackThankYou = true;
          this.storageService.remove('feedbackSubmitted', true);
          setTimeout(() => {
            this.feedbackThankYou = false;
          }, 20000);*/
        }
      });
  }

  ngAfterViewInit(): void {
    const ftUrl = this.translateService.instant("ftiGeneric.countryMenuUrl");
    const menuLabel = this.translateService.instant(
      "ftiGeneric.countryMenuLabel"
    );
    this.getFtCountryUrl = ftUrl;
    this.getFtcountryMenuLabel = menuLabel;

    this.loginService
      .isLoggedIn$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((loggedIn: boolean) => {
        this.isLoggedIn = loggedIn;
        this.changeDetector.detectChanges();
        this.loginService
          .loggedInUserType$()
          ?.pipe(takeUntil(this.unsubscribe$))
          .subscribe((userType: InUserType) => {
            this.userType = userType;
            this.changeDetector.detectChanges();
          });
      });
    this.changeDetector.detectChanges();
  }

  navigateToSearchResults(searchTerm?: any): void {
    let searchText = searchTerm ? searchTerm : this.searchText;
    this.router.navigate(["/search"], {
      queryParams: {query: searchText, filter: "all"},
    });
    if (searchText) {
      this.addToRecentSerch(searchText);
    }
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getMainMenuItems(data) {
    if (this.mainMenuList.length > 0) {
      return this.mainMenuList;
    }
    const arr = [];

    data?.childMenuItems.forEach((value) => {
      const result = this.getSteps(value?.childMenuItems);
      arr.push({
        name: value.name,
        childMenuItems: result.data.length
          ? result.data
          : value?.childMenuItems,
        links: value.links,
      });
    });
    this.mainMenuList = arr;
    return arr;
  }

  private getSteps(data) {
    let arr = [];
    const arrFinal = [];
    let count = 0;
    let counter = 0;
    let flag = false;

    data.forEach((value) => {
      if (value.links?.site) {
        counter++;
      }
    });

    data.forEach((value) => {
      if (value.links?.site) {
        flag = true;
        count++;
        arr.push(value);

        if (arr.length === 9) {
          arrFinal.push(arr);
          arr = [];
        }
        if (count === counter) {
          arrFinal.push(arr);
          arr = [];
        }
      } else {
        if (flag) {
          arrFinal.push(value);
        }
      }
    });

    return {data: arrFinal};
  }

  private initMenu() {
    this.menuItems = getMenus(this.page, this.component);
    const headerItems = {
      topMenuItems: this.menuItems?.siteMenuItems[0],
      mainMenuItems: this.getMainMenuItems(this.menuItems?.siteMenuItems[1]),
    };
    logger.debug("Got Header Menus!");
    this.menuList = this.menuItems?.siteMenuItems[1]?.childMenuItems;
    this.topMenuList = this.menuItems?.siteMenuItems[0]?.childMenuItems;
    this.mobileDDmenu = this.menuItems?.siteMenuItems[2]?.childMenuItems;
    this.menu = headerItems;
  }

  getSubChildForSmallMenu(subChild) {
    this.subChildMenuList = subChild.childMenuItems
  }

  /**
   * Return function to set short description for menu list
   * @param descData
   * @returns
   */
  getMenuItemShortDescription(descData) {
    return descData?.parameters['Short Description'];
  }

  // for overlay button in XM
  get menuModel() {
    return getModelFromRef(this.page, this.component, "menu");
  }

  get isPreview() {
    return this.page.isPreview();
  }

  desktopThirdLevel(menu) {
    //console.log(menu);
    const navURL = this.getUrl(menu);
    let aTarget = "_self";
    const targetParam: string = menu.parameters['Open in New Tab (True or False)'];
    if (targetParam.toLowerCase() === 'true') {
      aTarget = '_blank';
    }
    //console.log('navURL::', navURL, aTarget);
    if (navURL) {
      if (!(navURL.includes('http') || navURL.includes('www'))) {
        this.router.navigateByUrl(navURL);
      } else {
        window.open(navURL, aTarget);
      }
    }
  }

  public getUrl(menu?: any): string {
    // We can use both, ENV tokens and app=redirectGuestUrl parameter in menu URLs.
    // Token functionality gives more flexibility.
    // app=redirectGuestUrl param can be set only one URL
    if (
      menu.links?.site &&
      menu.parameters["Request parameter (key=value)"] ===
      "app=redirectGuestUrl"
    ) {
      return (
        this.appStateService.getftiAccountsUrl() +
        replaceTokens(menu.links?.site?.href, this.envConfig.getEnvConfig())
      );
    }
    return replaceTokens(menu.links?.site?.href, this.envConfig.getEnvConfig());
  }

  /**
   * functionality that allows users to hover over the target menu ID to see a list of target menus while checking the ID
   * @param event
   */
  topMenuShowOnHover(event: any) {
    if (event.target.id) {
      document.getElementById(event.target.id)?.click();
    }
  }

  showTabContentOnHover(event: any) {
    if (event.target.id) {
      document.getElementById(event.target.id)?.click();
      let clickedTab = event.target.getAttribute("data-tab-index");
      //console.log(clickedTab);

      let elId = document.querySelectorAll('.' + clickedTab + ' .tab-pane');
      for (let i = 0; i < elId.length; i++) {
        let cList = elId[i]?.classList;
        if (cList?.contains('keep-this-active')) {
          elId[i]?.classList.remove("keep-this-active");
          elId[i]?.classList.add("hide-this");
        } else {
          elId[i]?.classList.add("hide-this");
        }
      }
      let activeTab = event.target.getAttribute("data-tab-id");
      let activeTabDiv = document.getElementById(activeTab);
      let cTabList = activeTabDiv?.classList;
      if (cTabList?.contains('hide-this')) {
        cTabList.remove("hide-this");
      }
      cTabList?.add("keep-this-active");


      //View All Button show/hide Logic
      let clickedID = event.target.getAttribute("data-click-id");
      //console.log(clickedID);

      let el = document.querySelectorAll('.' + clickedID);
      for (let i = 0; i < el.length; i++) {
        let cList = el[i]?.classList;
        if (cList?.contains('keep-this-active')) {
          el[i]?.classList.remove("keep-this-active");
          el[i]?.classList.add("hide-this");
        } else {
          el[i]?.classList.add("hide-this");
        }
      }
      let activeLandingBtn = event.target.getAttribute("data-land-btn-id");
      let activeDiv = document.getElementById(activeLandingBtn);
      let cList = activeDiv?.classList;
      if (cList?.contains('hide-this')) {
        cList.remove("hide-this");
      }
      cList?.add("keep-this-active");
    }
  }

  getButtonClass(menuName?: any): string {
    let menuID = '';
    if (menuName) {
      menuID = menuName.replace(/\s+/g, '-') + "-landing btn btn-primary";
      return menuID.toLowerCase();
    }
    return menuID;
  }

  /**
   * Functionality for when a user clicks outside the menu, the Sticky Primary Menu can be closed.
   * @param param
   */
  contentOnHoverClose(param: any) {
    document.getElementById(param)?.click();
  }

  toggleMobileHeader(param: any) {
    this.activeMobileHeaderId = param;
    //console.log(this.activeMobileHeaderId)
  }

  toggleShowSideMenu() {
    this.ShowSideMenu = !this.ShowSideMenu;
  }

  toggleShowSearch() {
    this.searchText = '';
    this.ShowSearch = !this.ShowSearch;
  }

  toggleShowSuggest() {
    this.ShowSuggest = !this.ShowSuggest;
    this.ShowRecent = !this.ShowRecent;
  }

  noticeBannerToggle() {
    let topSpacElement: any = document.getElementById('handle-top-spacing');
    this.noticeBannerIsOpened = !this.noticeBannerIsOpened;
    if (!this.noticeBannerIsOpened) {
      if (window.innerWidth < 768) {
        topSpacElement?.classList.add('FT-default-top-mob');
      } else {
        topSpacElement?.classList.add('FT-default-top-web');
      }
    }
    this.closeMessage();
  }

  public closeMessage() {
    const cookkievalues = document.cookie.indexOf('outageBanner=');
    if (cookkievalues === -1) {
      this.noticeBannerIsOpened = false;
      const date = new Date();
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
      const expires = 'expires=' + date.toUTCString();
      document.cookie = 'outageBanner=active' + '; ' + expires + '; path=/';
    } else {
      this.noticeBannerIsOpened = true;
    }
    //this.changeDetector.detectChanges();
  }

  fixNotificationHeader() {
    if (this.noticeBannerIsOpened) {
      if (window.innerWidth < 767) {
        document.documentElement.style.setProperty('--mainContentMobileMargin', '300px');
      } else {
        document.documentElement.style.setProperty('--mainContentMargin', '287px');
      }
    }
  }

  getMenuID(menuName?: any, vpills?: boolean): string {
    if (!vpills) {
      let menuID = menuName.replace(/\s+/g, '-') + '-button';
      return menuID.toLowerCase();
    } else {
      let menuID = menuName.replace(/\s+/g, '-');
      return menuID.toLowerCase();
    }
  }

  countryUrl(url: string) {
    window.location.href = url;
  }

  /**
   * Function to change the segment
   * @param segment
   */
  handleSegmentChange = (segment: string) => {
    if (segment === "Distributor") {
      localStorage.setItem("current-segment", 'Distributor');
    } else {
      localStorage.removeItem("current-segment");
    }
    this.changeDetector.detectChanges();
  };

  /**
   * Function to change the theme mode to dark or light
   * @param mode
   * @param e
   */

  handleThemeModeClick = (mode: string, e?: any) => {
    if (e?.target?.checked) {
      mode = 'dark';
    }
    if (mode && mode == "dark") {
      localStorage.setItem("en-in-theme-mode", 'dark');
      this.document["body"].classList.add("page-dark-mode");
    } else {
      localStorage.setItem("en-in-theme-mode", 'light');
      this.document["body"].classList.remove("page-dark-mode");
    }
  };

  closeModal() {
    const modalElement = document.getElementById('searchModal');

    if (modalElement) {
      this.renderer.removeClass(modalElement, 'show');
      this.renderer.setStyle(modalElement, 'display', 'none');
    }
  }

  loadRecentSearches() {
    const recentSearches = JSON.parse(localStorage.getItem('recentSearches')) || [];
    this.recentSearches = recentSearches.slice(-4);
  }

  addToRecentSerch(searchTerm: any, type = 'general') {
    const MAX_RECENT_SEARCHES = 4;
    let recentSearches = JSON.parse(localStorage.getItem('recentSearches')) || [];
    recentSearches.unshift({term: searchTerm, type: type, timestamp: new Date().toISOString()});
    recentSearches = recentSearches.slice(0, MAX_RECENT_SEARCHES);
    localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
  }

  onSearchInputClick(): void {
    this.showAutoSuggestions = this.searchText?.length >= 3;
  }

  onSearchKeyUp(): void {
    this.showAutoSuggestions = false;
    if (this.searchText.length >= 3) {
      this.showAutoSuggestions = true;
      this.triggerChange = !this.triggerChange;
    }
  }

  onOpenSearch() {
    this.showModal = true;
  }

  activeFontSize: any = localStorage.getItem("activeFontSize") || 2;

  fontResize(size: any) {
    this.activeFontSize = size;
    this.commonService.setFontResize(size);
  }

  onCloseSidePannel() {
    this.ShowSideMenu = false;
  }

  setAnalytics(event) {
    this.activeMobileHeaderId = '';
    this.analyticsService.trackEvent({
      event: 'Event All pages',
      category: 'Top Navigation',
      action: event?.name,
      label: 'click',
    });
  }

  setAnalyticsTopSubMenu(topmenu, subMenu) {
    this.analyticsService.trackEvent({
      event: 'Event All pages',
      category: 'Top Navigation',
      action: topmenu?.name,
      label: subMenu?.name,
    });
  }

  loginGA() {
    this.analyticsService.trackEvent({
      event: 'Event homepage',
      category: 'Homepage',
      action: "Top Navigation",
      label: "Login / Register",
    });
    const queryParams = this.route.snapshot.queryParams;
    let userRedirectURI = this.loginURL;
    if (queryParams.utm_source && queryParams.utm_campaign) {
      const UTMParamsStr = location.href.split('?')[1];
      userRedirectURI = this.loginURL + '?' + UTMParamsStr;
    }

    this.windowRef.location.href = userRedirectURI;

  }

  public myAccounts(): void {
    this.loginService.myAccountsLink();
  }

  public signOut(justSignOut?: boolean): void {
    if (justSignOut) {
      this.loginService.logOut();
      return;
    }
    this.storageService.retrieve('userFeedbackStatus').then((userFeedback) => {
      if (userFeedback === 'false' || userFeedback === '') {
        this.loginService.setUserFeedbackDisplay(true);
      } else {
        this.loginService.logOut();
      }
    });
  }
}
